<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="4">
            <e-store-combo
              id="order-store_id"
              v-model="filters.storeId"
              :required="false"
              :label="$t('Loja')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              v-model="filters.name"
              name="name"
              type="text"
              :label="$t('Nome do Usuário')"
              :placeholder="$t('Nome')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              v-model="filters.userName"
              name="userName"
              type="text"
              :label="$t('Login')"
              :placeholder="$t('Login')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              v-model="filters.email"
              name="email"
              type="text"
              :label="$t('E-mail do Usuário')"
              :placeholder="$t('E-mail')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              v-model="filters.accessProfile"
              name="accessProfiles"
              type="vue-select"
              :label="$t('Perfis de Acesso')"
              :placeholder="$t('Selecione')"
              :options="accessProfiles"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="active"
              v-model="filters.active"
              type="vue-select"
              :label="$t('Status')"
              :placeholder="$t('Todos')"
              :options="activeInactiveTypes()"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="users-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('USER.NAME'))"
        :fields="fields"
        :items="users"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(action)="row">
          <e-grid-actions
            :show-update="$can('Update', 'User')"
            :show-delete="$can('Delete', 'User')"
            @update="showUpdateUserSidebar(row)"
            @delete="deleteUser(row)"
          />
        </template>
        <template #cell(active)="{ value }">
          <e-status-badge :status="value" />
        </template>
        <template #cell(stores)="data">
          <span v-if="data.item.allowAllStores">{{ $t('Todas') }}</span>
          <label v-else>{{ data.item.stores.map((i) => i.id).join(', ') }}</label>
        </template>
      </b-table>

      <b-row class="pt-1">
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="user-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <e-sidebar
      :title="edit ? $t('Editar Usuário') : $t('Novo Usuário')"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="500px"
      @save="saveUser"
      @hidden="onHideSidebar"
    >
      <template #content>
        <user-form
          ref="userForm"
          :user="user"
          :is-edit="edit"
        />
      </template>
    </e-sidebar>

    <b-modal
      id="history-modal"
      v-model="showHistory"
      :title="$t('Histórico')"
      ok-only
      centered
      size="lg"
      :ok-title="$t('voltar')"
    >
      <!-- <history-modal :tenant="tenant" /> -->
    </b-modal>

    <fab
      v-if="$can('Create', 'User')"
      :main-tooltip="$t('Adicionar usuário')"
      @click="showCreateUserSidebar"
    />
  </div>
</template>

<script>
import { BTable, BPagination, BRow, BCol, BModal } from 'bootstrap-vue'
import fab from '@/views/components/FAB.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPaginationSummary, EPageSizeSelector } from '@/views/components'
import { stringUtils, statusTypes } from '@/mixins'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import { mapActions, mapState } from 'vuex/'
import ESidebar from '@/views/components/ESidebar.vue'
import { getInitialSecurityUserForm } from '@/store/pages/security/user-maintain'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import UserForm from './components/UserForm.vue'

export default {
  components: {
    BTable,
    BPagination,
    BRow,
    BCol,
    BModal,
    EPaginationSummary,
    EPageSizeSelector,
    BCardActions,
    UserForm,
    fab,
    EFilters,
    EGridActions,
    ESidebar,
    EStoreCombo,
    EStatusBadge,
  },

  mixins: [stringUtils, statusTypes],

  data() {
    return {
      fetching: false,
      saving: false,
      showSidebar: false,
      edit: false,
      showHistory: false,
      tenant: '',
      // user: getInitialSecurityUserForm(),
    }
  },

  computed: {
    ...mapState('app', ['stores']),
    ...mapState('pages/security/userMaintain', ['user', 'users', 'filters', 'paging', 'sorting']),

    fields() {
      return [
        {
          label: this.$t('Ação'),
          key: 'action',
          class: 'text-center',
          thStyle: 'width: 100px',
        },
        {
          label: this.$t('Lojas'),
          key: 'stores',
          class: 'text-center',
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          class: 'text-center',
          sortable: true,
        },
        {
          label: this.$t('E-mail'),
          key: 'email',
          class: 'text-center',
          sortable: true,
        },
        {
          label: this.$t('Login'),
          key: 'userName',
          class: 'text-center',
          sortable: true,
        },
        {
          label: this.$t('Perfis de Acesso'),
          key: 'accessProfileNames',
          class: 'text-center',
          formatter: value => value.join(', '),
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '80px' },
        },
      ]
    },
    accessProfiles() {
      return this.$store.state.pages.security.accessProfiles.allAccessProfiles.map(item => ({
        ...item,
        label: item.name,
        value: item.name,
      }))
    },
  },

  mounted() {
    this.getData()
    this.$store.dispatch('pages/security/accessProfiles/fetchallAccessProfiles')
    // this.accessProfiles()
  },

  methods: {
    ...mapActions('pages/security/userMaintain', [
      'setUser',
      'cleanUserState',
      'fetchUsers',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchUsers()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    cpfCnpj(value) {
      return this.$options.filters.vueBrazil(value, 'cnpj')
    },

    async showCreateUserSidebar() {
      await this.cleanUserState()
      // this.user = getInitialSecurityUserForm()
      this.edit = false
      this.showSidebar = true
    },
    onHideSidebar() {
      this.cleanUserState()
      this.showSidebar = false
    },

    async showUpdateUserSidebar(row) {
      this.edit = false
      this.fetching = true
      const { id } = row.item
      try {
        this.showSidebar = true
        const { data: user } = await this.$http.get(`/api/users/${id}`)
        const userData = {
          ...getInitialSecurityUserForm(),
          ...user,
          accessProfilesSelected: user.accessProfileNames || [],
          store: user.store || [],
          storesSelected: user.stores ? user.stores.map(store => store.id.toString()) : [],
        }
        this.setUser(userData)

        this.edit = true
      } catch (error) {
        this.showGenericError({ error })
      } finally {
        this.fetching = false
      }
    },

    async saveUser() {
      if (await this.$refs.userForm.validate()) {
        try {
          this.saving = true

          const storesUpdated = [...this.user.stores]
          const storesSelected = [...this.user.storesSelected]
          const storesApi = storesUpdated.map(store => {
            const result = { ...store }
            if (!storesSelected.some(storeId => storeId === store.id.toString())) {
              result.isDeleted = true
            }
            return result
          })
          const newStores = storesSelected
            .filter(storeId => !storesApi.some(store => store.id.toString() === storeId))
            .map(storeId => ({ id: storeId }))

          const body = {
            id: this.user.id,
            active: this.user.active,
            name: this.user.name,
            document: this.user.document,
            telephoneNumber: this.user.telephoneNumber,
            userName: this.user.userName,
            email: this.user.email,
            allowAllStores: this.user.allowAllStores,
            password: this.user.password,
            stores: [...storesApi, ...newStores],
            accessProfileNames: this.user.accessProfilesSelected,
          }
          await this.$http({
            url: '/api/users',
            method: this.edit ? 'PUT' : 'POST',
            data: body,
          })
          this.showSuccess({
            message: this.$t(`${this.edit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
          })
          this.showSidebar = false
          this.getData()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
        }
      } else {
        this.showInvalidDataMessage()
      }
    },

    async deleteUser(user) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          await this.$http.delete(`/api/users/${user.id}`)
          this.getData()
          this.showSuccess({ message: this.$t('Usuário removido com sucesso') })
        }
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>
